import ReCAPTCHA from "react-google-recaptcha";
import { StickyContainer, Sticky } from "react-sticky";
import { Link as Boton } from "react-router-dom";
import { BiError } from "react-icons/bi";
import Container from "./styled";
// Custom Hooks
import { useForm } from "../../../hooks/useForm";
import { useLoading } from "../../../hooks/useLoading";
// Form
import { arrepentimientoForm } from "../../../utils/formInitialValues";
import { arrepentimientoReglas } from "../../../utils/formRules";
// Mail
import { arrepentimientoMail } from "../../../utils/mailContent";
import { sendFormMail } from "../../../utils/sendFormMail";
// Utils 
import { goToTop } from "../../../utils/scrollToTop";

export default function Formulario({ pasoSiguiente }) {
  
  // Custom Hooks
  const { inputValues, handleChange, handleValidation, formErrors, handleChangeExternal } = useForm(handleSubmit, arrepentimientoReglas, arrepentimientoForm)
  const { loading, initiateLoading } = useLoading()

  async function handleSubmit() {
    try {
      initiateLoading()
      await sendFormMail(inputValues, pasoSiguiente, arrepentimientoMail)
    } catch (error) {
      console.log(error)
    }
  } 

  return (
    <Container>
      {/* Pop up Errores */}
      {Object.keys(formErrors).length > 0 ?
          <div id="popup-error">
            {Object.values(formErrors).map(errorMessage => {
              return (
                <p>
                  <span>
                    <BiError />
                  </span>
                  {errorMessage}
                </p>
              )
            })}
          </div>
        : 
          null
      }
      {/* Max Width Container */}
      <div id="max-width-container">
        <div id="formulario-container" className="opacidad05">
          <h3>¿Te arrepentiste de la contratación de tu póliza?</h3>
          <p id="completa">
            Completá el siguiente formulario para poder solicitarlo, dentro de
            las próximas 24hs hábiles nos contactaremos para otorgarte tu número
            de solicitud generado y avanzar con el proceso.
          </p>
          <form>
            <div id="datos-personales">
              <h4>Datos personales</h4>
              {/* Input Nombre */}
              <div className="input-container" id="input-nombre-container">
                <input
                  type="text"
                  id="input-nombre"
                  name="nombre"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.nombre}
                  className={formErrors.nombre == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.nombre == null || inputValues.nombre == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-nombre"
                >
                  Nombre
                </label>
              </div>
              {/* Input Apellido */}
              <div className="input-container" id="input-apellido-container">
                <input
                  type="text"
                  id="input-apellido"
                  name="apellido"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.apellido}
                  className={formErrors.apellido == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.apellido == null || inputValues.apellido == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-apellido"
                >
                  Apellido
                </label>
              </div>
              {/* Input Email */}
              <div className="input-container" id="input-email-container">
                <input
                  type="email"
                  id="input-email"
                  name="email"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.email}
                  className={formErrors.email == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.email == null || inputValues.email == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-email"
                >
                  Email
                </label>
              </div>
              {/* Input DNI */}
              <div className="input-container" id="input-dni-container">
                <input
                  type="number"
                  id="input-dni"
                  name="dni"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.dni}
                  className={formErrors.dni == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.dni == null || inputValues.dni == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-dni"
                >
                  DNI
                </label>
              </div>
              {/* Input Telefono */}
              <div className="input-container" id="input-telefono-container">
                <input
                  type="number"
                  id="input-telefono"
                  name="telefono"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.telefono}
                  className={formErrors.telefono == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.telefono == null || inputValues.telefono == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-telefono"
                >
                  Teléfono
                </label>
              </div>
            </div>
            <div id="numero-poliza">
              <div id="titulo-container">
                <h4>Número de póliza</h4>
                <p>(Solo en caso de haberlo recibido)</p>
              </div>
              {/* Número de póliza */}
              <div className="input-container" id="input-poliza-container">
                <input
                  type="number"
                  id="input-poliza"
                  name="poliza"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.poliza}
                  className={formErrors.poliza == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.poliza == null || inputValues.poliza == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-poliza"
                >
                  Número de póliza
                </label>
              </div>
            </div>
            <div id="mensaje-motivo">
              <h4>Motivo del arrepentimiento</h4>
              {/* Textarea Mensaje */}
              <div className="input-container" id="textarea-mensaje-container">
                <textarea
                  type="text"
                  id="input-mensaje"
                  name="mensaje"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.mensaje ? inputValues.mensaje : ""}
                  className={formErrors.mensaje == null ? "" : "input-error"}
                ></textarea>
                <label
                  className={
                    inputValues.mensaje == null || inputValues.mensaje == ""
                      ? "label-down"
                      : "label-top"
                  }
                  for="input-mensaje"
                >
                  Ingresá tu comentario
                </label>
              </div>
            </div>
            <div id="captcha-container">
              <ReCAPTCHA
                sitekey="6LfvW5YdAAAAADsIry4KRJS5YkcRb7L20iJbYLzQ"
                size="compact"
                hl="es"
                theme="light"
                onChange={(value) => handleChangeExternal("captcha", value)}
              />
            </div>
            <div id="boton-container">
              <button type="submit" onClick={handleValidation} disabled={loading}>
                {loading ? "Enviando..." : "Enviar"}<span class="material-symbols-outlined">mail</span>
              </button>
            </div>
          </form>
        </div>
        <StickyContainer>
          <Sticky>
            {({ style }) => (
              <div id="plazos-container" className="opacidad05" style={style}>
                <p>Plazos</p>
                <p id="plazos-texto">
                  Podes solicitar el arrepentimiento hasta 10 días corridos a
                  partir de la contratación del servicio.
                </p>
                <div>
                  <p>Más información</p>
                  <a
                    target="_blank"
                    href="https://www.argentina.gob.ar/justicia/derechofacil/leysimple/boton-arrepentimiento#:~:text=%C2%BFQu%C3%A9%20es%20el%20bot%C3%B3n%20de,inicio%20del%20sitio%20de%20Internet."
                  >
                    Argentina.gob.ar
                  </a>
                </div>
                <div>
                  <p>Ver más</p>
                  <a href="https://www.seguroweb.com.ar/baja">
                    Baja de servicio
                  </a>
                  <Boton to="/contacto">Contacto</Boton>
                </div>
              </div>
            )}
          </Sticky>
        </StickyContainer>
      </div>
    </Container>
  );
}
