import React from "react";
import Container from "./styled";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import Seguros from "./seguros";
import { Link } from "react-router-dom";

export default function Footer({ link, marcas, pagina }) {
  return (
    <Container pagina={pagina}>
      {/* Seguros */}
      {marcas == "active" && (
        <Seguros seguromascotas={false} marcas={marcas}></Seguros>
      )}
      {/* Data Nav Container */}
      <div className="data-nav-container">
        {/* Data Container */}
        <div className="data-container">
          {/* Logo */}
          <Link to={link ? link : "/"}>
            <img
              alt="Seguro Motos Logo"
              src="/logos/gys/seguromascotas.svg"
            ></img>
          </Link>
          {/* Social Media Mobile Container */}
          <div className="social-media-mobile-container">
            {/* Botón Facebook */}
            <a href="https://www.facebook.com/seguromascotas/" target="_blank">
              <FaFacebookF />
            </a>
            {/* Botón Instagram */}
            <a
              href="https://www.instagram.com/seguromascotas/?hl=es-la"
              target="_blank"
            >
              <BsInstagram />
            </a>
            {/* Botón WhatsApp */}
            <a
              href="https://api.whatsapp.com/send?phone=541162255399&text=%C2%A1Hola%2C%20me%20gustar%C3%ADa%20obtener%20m%C3%A1s%20informaci%C3%B3n%20sobre%20SeguroMascotas!%20"
              target="_blank"
            >
              <BsWhatsapp />
            </a>
          </div>
          {/* Data */}
          <div className="data">
            <a href="tel:08102202373">0810-220-2373</a>
            <a href="tel:1152632581">(011) 5263-2581</a>
            <p>info@seguromascotas.com.ar</p>
            <p>Suipacha 245 1° Piso CABA</p>
          </div>
        </div>
        {/* Navs Container */}
        <div className="navs-container">
          {/* Columna */}
          <div className="navs-container-columna">
            {/* Titulo */}
            <p>Mapa del sitio</p>
            {/* Nav */}
            <nav>
              <li>
                <Link to="/">Inicio</Link>
              </li>
              <li>
                <a
                  href="https://www.seguromascotas.com.ar/blog/"
                  target="_blank"
                >
                  Blog SeguroMascotas
                </a>
              </li>
              <li>
                <Link to="/federacion-patronal">Federación Patronal</Link>
              </li>
              <li>
                <Link to="/referidos">Referidos</Link>
              </li>
            </nav>
          </div>
          {/* Column Nav Container */}
          <div className="navs-container-columna">
            {/* Titulo */}
            <p>Ayuda y legales</p>
            {/* Nav */}
            <nav>
              <li>
                <Link to="/politicas-y-terminos">Políticas y términos</Link>
              </li>
              <li>
                <a href="https://www.seguroweb.com.ar/baja">Baja de servicio</a>
              </li>
              <li>
                <Link to="/arrepentimiento-de-compra">
                  Arrepentimiento de compra
                </Link>
              </li>
              <li>
                <Link to="/contacto">Contacto</Link>
              </li>
            </nav>
          </div>
          {/* Column Nav Container */}
          <div className="navs-container-columna">
            {/* Titulo */}
            <p>La empresa</p>
            {/* Nav */}
            <nav>
              <li>
                <a href="https://gys.com.ar/" target="_blank">
                  Página web
                </a>
              </li>
              <li>
                <Link to="/sobre-nosotros">Sobre nosotros</Link>
              </li>
              <li>
                <Link to="/forma-parte">Forma parte de SeguroMascotas</Link>
              </li>
            </nav>
          </div>
        </div>
      </div>
      {/* Social Media y Logos Container */}
      <div className="social-media-logos-container">
        {/* Social Media Container */}
        <div className="social-media-container">
          {/* Facebook Button */}
          <a href="https://www.facebook.com/seguromascotas/" target="_blank">
            <FaFacebookF />
          </a>
          {/* Instagram Button */}
          <a
            href="https://www.instagram.com/seguromascotas/?hl=es-la"
            target="_blank"
          >
            <BsInstagram />
          </a>
          {/* WhatsApp Button */}
          <a
            href="https://api.whatsapp.com/send?phone=541162255399&text=%C2%A1Hola%2C%20me%20gustar%C3%ADa%20obtener%20m%C3%A1s%20informaci%C3%B3n%20sobre%20SeguroMascotas!%20"
            target="_blank"
          >
            <BsWhatsapp />
          </a>
        </div>
        {/* Logos Container */}
        <div className="logos-container">
          {/* Logo */}
          <a
            href="https://www.seguroweb.com.ar/"
            target="_blank"
            id="logo-sw-boton"
          >
            <img
              alt="Logo Seguro Web"
              src="/logos/footer/seguroweb.svg"
              id="logo-sw"
            ></img>
          </a>
          {/* Logo */}
          <img
            alt="Logo Lets Encrypt"
            src="/logos/footer/letsencrypt.png"
            id="logo-encrypt"
          ></img>
          {/* Logo */}
          <a
            href="https://www.gyssrl.com.ar/webroot/footer/certificadoapt.pdf"
            target="_blank"
            id="logo-SSN"
          >
            <img alt="Logo SSN" src="/logos/footer/ssn.png" id="logoSSN"></img>
          </a>
          {/* Logo */}
          <img alt="Logo PDP" src="/logos/footer/pdp.png" id="logo-PDP"></img>
          {/* Logo */}
          <a
            href="https://servicios1.afip.gov.ar/clavefiscal/qr/response.aspx?qr=vfDql9TuSM09b_FRxAacFg,,"
            target="_F960AFIPInfo"
            id="logo-fiscal"
          >
            <img
              alt="Logo Data Fiscal"
              src="/logos/footer/datafiscal.webp"
            ></img>
          </a>
        </div>
        <img
          alt="Edificios del isotipo de Gestión y Servicios"
          src="/footer/edificios-borde.svg"
          id="edificios"
        ></img>
      </div>
      {/* Texto Copyright */}
      <div id="texto-copyright-container">
        <p>
          © Copyright 2023 SeguroMascotas | Todos los derechos reservados |
          SeguroMascotas es marca de Gestión y Servicios S.R.L. (Matrícula 0387)
        </p>
      </div>
    </Container>
  );
}
